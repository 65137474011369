import React, { useMemo } from "react"

interface TinyMCEViewerProps {
    content: string;
    className?: string;
}

const TinyMCEViewer = ({content, className}: TinyMCEViewerProps) => {


    const parsed = useMemo(() => {
        if (!content) {
            return null;
        }

        const adRegex = /<table [^>]*>.+?(?=#ad#).+?(?=<\/table>)<\/table>/gs
        const adReplacement = `<div data-fuse="23198949897" style="min-height: 90px; margin-top:30px; margin-bottom:30px;"></div>`
        return content.replaceAll(adRegex, adReplacement)
    }, [content])


    if(!parsed) {
        return null;
    }

    return (
        <div className={className} dangerouslySetInnerHTML={{ __html: parsed }} />
    )
}

export default TinyMCEViewer;